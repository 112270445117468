var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-wrapper"},[(_vm.showForm)?_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypeOptions,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"bankId","label":"社会保障卡开户银行","placeholder":"请输入社会保障卡开户银行","required":""},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"社会保障卡金融账号","placeholder":"请输入社会保障卡金融账号","required":""},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"eduTalentType","label":"教育人才类别","placeholder":"请输入教育人才类别","required":"","readonly":"","disabled":""},model:{value:(_vm.form.eduTalentType),callback:function ($$v) {_vm.$set(_vm.form, "eduTalentType", $$v)},expression:"form.eduTalentType"}}),_c('van-field',{attrs:{"name":"workCmpy","label":"工作单位","placeholder":"请输入工作单位","required":""},model:{value:(_vm.form.workCmpy),callback:function ($$v) {_vm.$set(_vm.form, "workCmpy", $$v)},expression:"form.workCmpy"}})],1):_c('div',[_c('van-empty',{attrs:{"description":"暂无教育人才认证,无法办理"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }